import React, { Fragment, PureComponent } from "react";
import { AJAX_PUBLIC_REQUEST, CURRENCY_FORMAT, GET_STORAGE, SET_STORAGE, USER } from "../../Constants/AppConstants";
import Parser from 'html-react-parser';
import QuestionnaireProductListGrid from './QuestionnaireProductListGrid';
import { NavLink } from 'react-router-dom';
import HomeIcon from './../../Assets/images/refer_images/home_icon.svg'
import MaleIcon from './../../Assets/images/public_images/quiz_male_icon.svg'
import FemaleIcon from './../../Assets/images/public_images/quiz_female_icon.svg'
import MaleIconActive from './../../Assets/images/public_images/quiz_male_icon_active.svg'
import FemaleIconActive from './../../Assets/images/public_images/quiz_female_icon_active.svg'
import RightArrow from './../../Assets/images/public_images/quiz-arrow-right.svg'
import LeftArrow from './../../Assets/images/public_images/quiz-arrow-left.svg'
import history from "../../history";

class Questionnaires extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      questionnaires: [],
      currentQuestionIndex: 0,
      step: "gender",
      gender: "",
      responses: [],
      firstMonthflavorId: '',
      filtered_products: [],
      submitted: false,
      isNextButtonDisabled: true,
      user_id: '',
      selectedGender: ''
    };
    document.title = "Products - Prestige Labs";
  }

  componentDidMount() {
    document.querySelector("body").scrollIntoView();
    document.body.classList.remove("home_page");
    this.getQuestionnaires();
  }

  getQuestionnaires = () => {
    AJAX_PUBLIC_REQUEST("POST", "menu/getQuestionnaires", {}).then((results) => {
      if (results.response.code === 1000) {
        this.setState({
          allQuestions: results.response.data,
          loading: false,
        });
      }
    });
  };

  handleGenderSelect = (selectedGender) => {
    this.setState({ selectedGender: selectedGender });
    const filteredQuestions = this.state.allQuestions.filter(
      (question) => question.gender === selectedGender
    );

    if (this.nextQuestionTimer) {
      clearTimeout(this.nextQuestionTimer);
    }
    this.setState({
      gender: selectedGender,
      questionnaires: filteredQuestions,
      currentQuestionIndex: 0,
      responses: [],
      isGenderButtonAnimating: true,
      isNextButtonDisabled: true,
    });

    this.nextQuestionTimer = setTimeout(() => {
      if (filteredQuestions.length > 0) {
        this.handleContinue();
      }
    }, 5000);
  };


  handleResponse = (answer) => {
    const { currentQuestionIndex, responses, timeoutId, questionnaires } = this.state;
    const updatedResponses = [...responses];
    updatedResponses[currentQuestionIndex] = answer;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    const newTimeoutId = setTimeout(() => {
      if (currentQuestionIndex < questionnaires.length - 1) {
        this.setState((prevState) => ({
          currentQuestionIndex: prevState.currentQuestionIndex + 1,
          isButtonAnimating: false,
          timeoutId: null,
        }));
      } else {
        this.handleSubmit();
      }
    }, 5000);

    this.setState({
      responses: updatedResponses,
      isButtonAnimating: true,
      timeoutId: newTimeoutId,
    });
  };

  handleNext = () => {
    const { currentQuestionIndex, questionnaires, timeoutId } = this.state;

    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    if (currentQuestionIndex < questionnaires.length - 1) {
      this.setState({
        currentQuestionIndex: currentQuestionIndex + 1,
        isButtonAnimating: false,
        isNextButtonDisabled: false,
        timeoutId: null,
      });
    }
  };

  handleBack = () => {
    const { currentQuestionIndex } = this.state;
    if (currentQuestionIndex > 0) {
      this.setState({ currentQuestionIndex: currentQuestionIndex - 1, filtered_products: [] });
    } else {
      this.setState({ step: "gender" });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let user_id = null;
    if (GET_STORAGE(USER)) {
      const user = JSON.parse(GET_STORAGE(USER));
      user_id = user.user_id;
      this.setState({ user_id: user_id });
    }

    const { responses, questionnaires, selectedGender } = this.state;

    const quizPayload = {
      user_id: user_id,
      gender: selectedGender,
      questions: questionnaires.map((question, index) => ({
        question_id: question.id,
        user_input: responses[index]
      }))
    };

    this.setState({ loading: true, submitted: false });

    // First API call to createQuizLogs
    AJAX_PUBLIC_REQUEST("POST", "menu/createQuizLogs", quizPayload)
      .then((quizResults) => {
        if (quizResults.response.code === 1000) {
          console.log("Quiz log created successfully:", quizResults.response);

          const bundlePayload = {
            is_questionnaires: 1,
            questions: quizPayload.questions
          };

          return AJAX_PUBLIC_REQUEST("POST", "product/getCustomBundleList", bundlePayload);
        } else {
          throw new Error(quizResults.response.message);
        }
      })
      .then((bundleResults) => {
        if (bundleResults.response.code === 1000) {
          const bundleProduct = bundleResults.response.data;
          const filtered_products = bundleProduct.filter((product) =>
            product.variations ? Object.values(product.variations).some(variation => variation.stock_status === "instock") : true
          );

          this.setState({
            filtered_products: filtered_products,
            loading: false,
            item_count: parseInt(filtered_products.length),
            total_records: parseInt(bundleResults.response.total_records),
            submitted: true,
          });

          SET_STORAGE("productDiscount", JSON.stringify(bundleResults.response.product_discount));
          SET_STORAGE("CustomSubscriptionDetail", JSON.stringify(bundleResults.response.subscription_detail));
        } else {
          throw new Error(bundleResults.response.message);
        }
      })
      .catch((error) => {
        this.setState({
          error: Parser("<p className='text-danger'>" + error.message + "</p>"),
          loading: false,
          item_count: 0,
          total_records: 0,
          submitted: false,
        });
      });
  };

  handleContinue = () => {
    this.setState({ step: "questionnaire" });
  };
  handleGenderBack = () => {
    history.push("/");
  }

  activeFlavors = (product) => {
    const monthIdAsInt = parseInt("1")
    const variationsForMonth = Object.values(product.variations)
      .filter(variation => variation.month == monthIdAsInt);
    const activeFlavorIds = variationsForMonth.map(variation => variation.flavor_id);
    const updatedFlavors = product.flavors.filter(flavor => activeFlavorIds.includes(flavor.id));
    let defaultFlavor = null;
    if (updatedFlavors.length > 0) {
      defaultFlavor = updatedFlavors.find(flavor => flavor.id == this.state.firstMonthflavorId) || updatedFlavors[0];
    }

    if (defaultFlavor) {
      this.setState({
        activeFlavors: updatedFlavors,
      });
    }
  };

  handleCreateCustomBundle = () => {
    const recommendedProducts = GET_STORAGE('recommendedProducts');
    if (recommendedProducts) {
      console.log(JSON.parse(recommendedProducts), 'recommendedProducts');
      SET_STORAGE("Bundle", recommendedProducts); // Ensure the bundle is set first
    }
    this.props.history.push("/create-custom-bundle", { payload: this.state.payload });
  };


  render() {
    const {
      loading,
      step,
      gender,
      questionnaires,
      currentQuestionIndex,
      responses,
      filtered_products,
      firstMonthflavorId
    } = this.state;
    const currentQuestion = questionnaires[currentQuestionIndex];
    const progress =
      questionnaires.length > 0
        ? ((currentQuestionIndex + 1) / questionnaires.length) * 100
        : 0;

    const ActiveFirstMonthFlavors = this.state.activeFlavors;
    if (loading) {
      return <div className="loading container full_page_loader"></div>;
    }

    return (
      <div className="suplements_blk questinaries_con_blk">
        <div className="container">
          <div className='pro_head_blk'>
            <h2 className="product_itle text_black let_spa_2 font_24 fw_el_semi mb_6">Tell us more about you</h2>
            <nav aria-label="breadcrumb" className="m-0">
              <ol className="breadcrumb cus_breadcrumb pro_breadcrumb">
                <li className="breadcrumb-item"><NavLink to="/"><img src={HomeIcon} alt="home_icon" /></NavLink></li>
                <li className="breadcrumb-item active" aria-current="page"> ~ Survey</li>
                <li className="breadcrumb-item active" aria-current="page"> ~ Tell us more about you</li>
              </ol>
            </nav>
          </div>
          <div className="quiz_blk">
            <Fragment>
              <div className="questionnaire-page">
                {step == "gender" ? (
                  <div className="gender-selection">
                    {/* <h1 className="question-title font_44 mb_30 text-center fw_ar_semi">Tell us more about you</h1> */}
                    <h3 className="font_44 mb_30 text-center fw_ar_semi">What’s your Gender?</h3>
                    <div className="gender-options mb_30 d-flex justify-content-center">
                      <button className={`quiz_options d-flex flex-column align-items-center justify-content-center mr_27 gender-card ${gender === "male" ? "selected" : ""}`}
                        onClick={() => this.handleGenderSelect("male")}>
                        <img src={MaleIcon} alt="quiz_male_icon" className="mb_5 active_icon" />
                        <img src={MaleIconActive} alt="quiz_male_icon" className="mb_5 inactive_icon" />
                        <span className="font_20 letter_spacing_2per fw_ar_bold d-block text-center">I’m a Male</span>
                      </button>
                      <button className={`quiz_options d-flex flex-column align-items-center justify-content-center ml_27 gender-card ${gender === "female" ? "selected" : ""}`}
                        onClick={() => this.handleGenderSelect("female")}>
                        <img src={FemaleIcon} alt="quiz_female_icon" className="mb_5 active_icon" />
                        <img src={FemaleIconActive} alt="quiz_female_icon" className="mb_5 inactive_icon" />
                        <span className="font_20 letter_spacing_2per fw_ar_bold d-block text-center">I’m a Female</span>
                      </button>
                    </div>
                    <div className="navigation-buttons gender_quiz_button mx-auto d-flex justify-content-between">
                      <button className="back_button d-flex justify-content-center align-items-center gender_back"
                        onClick={this.handleGenderBack}>
                        <img src={LeftArrow} alt="quiz_female_icon" className="" />
                        <span className="d-block ml_3 font_20 text_pri fw_ar_reg">Back</span>
                      </button>
                      <button className={`next_button buy_now fw_el_reg font_20 text_white d-flex align-items-center justify-content-center ${this.state.isGenderButtonAnimating ? 'fade-to-dark-purple' : ''}`}
                        onClick={this.handleContinue}
                        disabled={!gender}
                        style={{
                          opacity: !gender ? 0.7 : 1,
                          cursor: !gender ? 'not-allowed' : 'pointer',
                        }}>
                        <span className="d-block">Next</span>
                        <img src={RightArrow} alt="quiz_female_icon" className="ml_3" />
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="questionnaire-container">
                    <div className="progress-bar-container">
                      <span className="progress-text font_16 d-block fw_ar_semi mb_8">
                        {Math.min(currentQuestionIndex + 1, questionnaires.length)}/{questionnaires.length}
                      </span>
                    </div>
                    <div className="d-flex quest_pro_blk justify-content-between mb_30">
                      {questionnaires.map((_, index) => (
                        <div key={index} className={`ques_pro_each ${index <= currentQuestionIndex ? "selected" : ""}`} style={{ width: `calc(${100 / questionnaires.length}% - 15px)` }}></div>
                      ))}
                    </div>
                    {this.state.submitted && this.state.filtered_products.length > 0 ? (
                      <>
                        <h3 className="font_44 mb_7 text-center fw_ar_semi">Your Custom Supplement Bundle</h3>
                        {/* {this.state.submitted && this.state.filtered_products.length > 0 && (
                             <div class="d-flex justify-content-end w-100 quiz_bundle">
                                <button className="shop_more add_cart_btn font_18 banner_text text-center fw_ar_reg add_to_bundle add_bundle_btn proceed_1" onClick={this.handleCreateCustomBundle}>
                                    Proceed to Create Custom Bundle
                                </button>
                            </div>
                          )} */}
                        <div className="row sup_pro_row">
                          {this.state.filtered_products.length <= 0 && this.state.submitted ? (
                            <h3>No Product Matched!</h3>
                          ) : (
                            this.state.filtered_products.map((product) => {
                              return product.is_bundle == 0 ? (
                                <QuestionnaireProductListGrid
                                  key={Math.random()}
                                  product={product}
                                />
                              ) : null;
                            })
                          )}
                          {this.state.submitted && this.state.filtered_products.length > 0 && (
                            <>
                              <div class="d-flex justify-content-end w-100 quiz_bundle quiz_bundle_btn">
                                <button className="shop_more add_cart_btn font_18 banner_text text-center fw_ar_reg add_to_bundle add_bundle_btn proceed" onClick={this.handleCreateCustomBundle}>
                                  Proceed to Create Custom Bundle
                                </button>
                              </div>
                              <div class="justify-content-center w-100 quiz_bundle hide_button hide_blk">
                                <button className="shop_more add_cart_btn font_18 banner_text text-center fw_ar_reg add_to_bundle add_bundle_btn proceed" onClick={this.handleCreateCustomBundle}>
                                  Proceed to Create Custom Bundle
                                </button>
                              </div>
                            </>
                          )}
                        </div>
                      </>
                    ) : (
                      <Fragment>
                        <h3 className="font_44 mb_30 text-center fw_ar_semi">{currentQuestion.question}</h3>
                        <div className="options second_options_blk d-flex flex-wrap justify-content-center mb_20">
                          <button
                            className={`quiz_options sec_quiz_options letter_spacing_2per font_20 fw_ar_bold d-flex justify-content-center align-items-center ${responses[currentQuestionIndex] === "YES" ? "selected" : ""
                              }`}
                            onClick={() => this.handleResponse("YES")}
                          >
                            Yes
                          </button>
                          <button
                            className={`quiz_options sec_quiz_options letter_spacing_2per font_20 fw_ar_bold d-flex justify-content-center align-items-center ${responses[currentQuestionIndex] === "NO" ? "selected" : ""
                              }`}
                            onClick={() => this.handleResponse("NO")}
                          >
                            No
                          </button>
                        </div>
                        <div className={`navigation-buttons first_quiz_button mx-auto d-flex justify-content-between align-items-center ${this.state.submitted && this.state.filtered_products.length > 0 ? 'mb_30' : ''}`}>
                          <button
                            className="back_button d-flex justify-content-center align-items-center"
                            onClick={this.handleBack}
                          >
                            <img src={LeftArrow} alt="quiz_female_icon" className="" />
                            <span className="d-block ml_3 font_20 text_pri fw_ar_reg ">Back</span>
                          </button>
                          {currentQuestionIndex < questionnaires.length - 1 ? (
                            <button
                              className={`next_button buy_now fw_el_reg font_20 text_white d-flex align-items-center justify-content-center ${this.state.isButtonAnimating ? 'fade-to-dark-purple' : ''}`}
                              onClick={this.handleNext}
                              disabled={!responses[currentQuestionIndex]}
                              style={{
                                opacity: !responses[currentQuestionIndex] ? 0.7 : 1,
                                cursor: !responses[currentQuestionIndex] ? 'not-allowed' : 'pointer',
                              }}>
                              <span className="d-block">Next</span>
                              <img src={RightArrow} alt="quiz_female_icon" className="ml_3" />
                            </button>
                          ) : (
                            <Fragment>
                              {!this.state.submitted ? (
                                <button
                                  className={`next_button buy_now fw_el_reg font_20 text_white d-flex align-items-center justify-content-center ${this.state.isButtonAnimating ? 'fade-to-dark-purple' : ''
                                    }`}
                                  onClick={(e) => this.handleSubmit(e)}
                                  disabled={!responses[currentQuestionIndex]}
                                >
                                  <span>{this.state.isButtonAnimating ? 'Submitting...' : 'Submit'}</span>
                                </button>

                              ) : ''}
                            </Fragment>
                          )}
                        </div>
                      </Fragment>

                    )}
                  </div>
                )}
              </div>
            </Fragment>
          </div>
        </div>
      </div>
    );
  }
}

export default Questionnaires;
